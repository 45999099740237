import {
  Button, Col, Image, Row,
} from 'react-bootstrap';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import CoverAura from '../../images/products/aura-playing-cards/cover.jpg';
import Intro01 from '../../images/products/aura-playing-cards/intro-01.jpg';
import Intro02 from '../../images/products/aura-playing-cards/intro-02.jpg';
import Intro03 from '../../images/products/aura-playing-cards/intro-03.jpg';
import Intro04 from '../../images/products/aura-playing-cards/intro-04.jpg';
import Intro05 from '../../images/products/aura-playing-cards/intro-05.gif';
import Intro06 from '../../images/products/aura-playing-cards/intro-06.jpg';
import Intro07 from '../../images/products/aura-playing-cards/intro-07.jpg';
import Intro08 from '../../images/products/aura-playing-cards/intro-08.jpg';
import Intro09 from '../../images/products/aura-playing-cards/intro-09.jpg';
import Intro10 from '../../images/products/aura-playing-cards/intro-10.jpg';
import Intro11 from '../../images/products/aura-playing-cards/intro-11.jpg';
import Intro12 from '../../images/products/aura-playing-cards/intro-12.jpg';
import Intro13 from '../../images/products/aura-playing-cards/intro-13.jpg';
import Intro14 from '../../images/products/aura-playing-cards/intro-14.jpg';
import Intro15 from '../../images/products/aura-playing-cards/intro-15.jpg';
import Intro16 from '../../images/products/aura-playing-cards/intro-16.jpg';
import IntroCardSpin from '../../images/products/aura-playing-cards/intro-card-spin.gif';
import IntroFriffle from '../../images/products/aura-playing-cards/intro-friffle.gif';
import IntroIndex from '../../images/products/aura-playing-cards/intro-index.png';
import IntroSuits from '../../images/products/aura-playing-cards/intro-suits.png';
import * as styles from './Products.module.scss';

function AuraPlayingCards() {
  return (
    <Layout>
      <SEO title="Aura Playing Cards" />
      <Row className="bg-primary g-0">
        <Col>
          <Row className="container mx-auto py-5">
            <Col>
              <Row>
                <Col xs={12} md>
                  <Image
                    src={CoverAura}
                    alt="Aura Playing Cards"
                    className={`w-100 ${styles.imageItem}`}
                  />
                </Col>
                <Col xs={12} md>
                  <h1>Aura Playing Cards</h1>
                  <h3>
                    <del className="me-2">$12.00</del>
                    $10.00
                  </h3>
                  <p className={`font-weight-light ${styles.leading}`}>
                    Aura playing cards, a 100% custom deck of playing cards, an
                    awesome combination of ideas, patterns, and UV coating. This
                    deck is not only outstanding designs but also the great
                    handling by using the Vintage Stock and the Legendary
                    Finish. That allows you to do your performance well, such as
                    magic tricks, cardistry, card games, or even collection. The
                    Aura playing cards are like an artwork that you are rarely
                    seen before in playing cards.
                  </p>
                  <Button variant="secondary" disabled>
                    SOLD OUT
                  </Button>
                  {' '}
                  Limited to 500,
                  {' '}
                  <a
                    className={styles.textKickstarterGreen}
                    href="https://www.kickstarter.com/projects/waltty/aura-playing-cards-by-waltty-playing-card-studio?ref=official"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Kickstarter
                  </a>
                  {' '}
                  exclusive
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="bg-dark g-0">
        <Col>
          <Row className="container mx-auto py-5">
            <Col>
              <Row className="mb-4">
                <Col xs={12} md>
                  <h2>Aura Playing Cards</h2>
                  <p className={`font-weight-light ${styles.leading}`}>
                    You can think of an aura as a reflection of your state of
                    being. When it comes to something you love, you can’t hide
                    your feelings of joy. People can feel your passion for it,
                    as a unique aura of you. That’s the inspiration to the Aura
                    playing cards.
                  </p>
                  <p className={`font-weight-light ${styles.leading}`}>
                    We believe that the very thing makes us different is what
                    makes us special. Everyone has their aura that different
                    meanings for them. For us, the strong passion for playing
                    cards is it. We think playing cards appeal to us not only
                    about the cool patterns but also the stories behind. So, we
                    are trying to bring this concept to life with the Aura
                    playing cards.
                  </p>
                </Col>
                <Col xs={12} md className="text-center">
                  <Image
                    src={Intro01}
                    alt="Back design"
                    className={`w-100 ${styles.imageItem}`}
                  />
                  <p>Aura Playing Cards</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="sectionMedium g-0">
        <Col>
          <Row className="container mx-auto py-5">
            <Col>
              <Row>
                <Col xs={12} md className="text-center">
                  <Image
                    src={Intro02}
                    alt="The ultraviolet coating effect"
                    className={`w-100 ${styles.imageItem}`}
                  />
                  <p>The ultraviolet coating effect</p>
                </Col>
                <Col xs={12} md>
                  <h2>The Design</h2>
                  <p className={`font-weight-light ${styles.leading}`}>
                    We did testing and prototyping in the development. It is no
                    easy thing, using UV coating on printed playing cards paper
                    within poker finish. In the process, it is not easier than
                    foil stamping. Furthermore, keeping the nice handling,
                    making sure color is printed right on the printed area, and
                    thinking locations on the patterns to spot the concept. We
                    have solved many problems to create this deck and make sure
                    that everything runs great even already over our budget.
                  </p>
                  <p className={`font-weight-light ${styles.leading}`}>
                    The cost price of this deck will be twice as much as that of
                    the regular one. We made a difficult decision that is
                    limited the number of decks, because of the high cost of
                    production and complex manufacturing processes. This is a
                    limited edition to only
                    {' '}
                    <span className="font-weight-bold">500</span>
                    {' '}
                    decks of Aura
                    playing cards using UV coating. To keep the unique artwork,
                    we never will be reprinted. Pledging a reward on
                    {' '}
                    <a
                      className={styles.textKickstarterGreen}
                      href="https://www.kickstarter.com/projects/waltty/aura-playing-cards-by-waltty-playing-card-studio?ref=official"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Kickstarter
                    </a>
                    {' '}
                    makes this project a reality is the only way you can get
                    this deck so far.
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="bg-primary g-0">
        <Col>
          <Row className="container mx-auto py-5">
            <Col>
              <Row className="mb-4">
                <Col xs={12} md>
                  <h2>The Back</h2>
                  <p className={`font-weight-light ${styles.leading}`}>
                    The back design is a perfect combination of the patterns and
                    UV coating. It’s great for magic, cardistry, and
                    performance. The idea behind the design is trying to present
                    a unique aura of life. Each white line is like a part of
                    your life, maybe the four favorite deck of playing cards,
                    maybe the four games, and maybe the four things in your
                    bucket list. When spinning the card, it looks like all the
                    lines are connected within the unique aura. It’s just like
                    playing your biographical movie.
                  </p>
                </Col>
                <Col xs={12} md className="text-center">
                  <Image
                    src={Intro03}
                    alt="Back design"
                    className={`w-100 ${styles.imageItem}`}
                  />
                  <p>Back design</p>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md className="text-center">
                  <Image
                    src={IntroCardSpin}
                    alt="The card spin with Aura playing cards"
                    className={`w-100 ${styles.imageItem}`}
                  />
                  <p>The card spin with Aura playing cards</p>
                </Col>
                <Col xs={12} md className="text-center">
                  <Image
                    src={IntroFriffle}
                    alt="Friffle with Arua playing cards"
                    className={`w-100 ${styles.imageItem}`}
                  />
                  <p>Friffle with Arua playing cards</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="bg-dark g-0">
        <Col>
          <Row className="container mx-auto py-5">
            <Col>
              <Row>
                <Col xs={12} md className="text-center">
                  <Image
                    src={Intro04}
                    alt="The Tuck Box"
                    className={`w-100 ${styles.imageItem}`}
                  />
                  <p>The Tuck Box</p>
                </Col>
                <Col xs={12} md>
                  <h2>The Tuck Box</h2>
                  <p className={`font-weight-light ${styles.leading}`}>
                    The tuck box design includes many elements. An aura logo on
                    the front of the box, that consists of the color scheme for
                    this deck and AURA. Of course, the back design on the back
                    of the box. It looks amazing using UV coating to represent
                    the pattern. It’s really interesting when you see it under
                    the light. Also, you can feel it under your fingers.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md className="text-center">
                  <Image
                    src={Intro05}
                    alt="The ultraviolet coating effect with tuck box"
                    className={`w-100 ${styles.imageItem}`}
                  />
                  <p>The ultraviolet coating effect</p>
                </Col>
                <Col xs={12} md className="text-center">
                  <Image
                    src={Intro06}
                    alt="the front of the tuck box"
                    className={`w-100 ${styles.imageItem}`}
                  />
                  <p>The Tuck Box</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="sectionMedium g-0">
        <Col>
          <Row className="container mx-auto py-5">
            <Col>
              <Row className="mb-4">
                <Col xs={12} md>
                  <h2>The Pips</h2>
                  <p className={`font-weight-light ${styles.leading}`}>
                    Just like the standard playing cards using the French suits.
                    We followed the iconic symbols to customize Aura’s four
                    suits. The pips are quite simple and easy to identify. Also,
                    we customize the perfect match index labels for the pips.
                  </p>
                </Col>
                <Col xs={12} md className="text-center">
                  <Image
                    src={IntroSuits}
                    alt="The four suits"
                    className={`w-100 ${styles.imageItem}`}
                  />
                  <p>The four suits</p>
                  <Image
                    src={IntroIndex}
                    alt="The indexes"
                    className={`w-100 ${styles.imageItem}`}
                  />
                  <p>The indexes</p>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col xs={12} md className="text-center">
                  <Image
                    src={Intro08}
                    alt="The four suits"
                    className={`w-100 ${styles.imageItem}`}
                  />
                  <p>The face cards</p>
                </Col>
                <Col xs={12} md>
                  <h2>The Faces</h2>
                  <p className={`font-weight-light ${styles.leading}`}>
                    The face cards are fully custom, and each design is based on
                    the standard face. Each of the face cards is a combination
                    of traditional look and simplified patterns in the new
                    colorway for design. These changes make the face cards so
                    unique, and this deck still playable and performable.
                  </p>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col xs={12} md>
                  <h2>The Aces</h2>
                  <p className={`font-weight-light ${styles.leading}`}>
                    The aces are featuring the giant four suits. Each one with a
                    striking outline to represent the aura concept. The ace of
                    spades is always the particular one, which is the biggest
                    pip and the iconic Aura logo inside the pattern.
                  </p>
                </Col>
                <Col xs={12} md className="text-center">
                  <Image
                    src={Intro09}
                    alt="The Aces"
                    className={`w-100 ${styles.imageItem}`}
                  />
                  <p>The Aces</p>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col xs={12} md className="text-center">
                  <Image
                    src={Intro10}
                    alt="The four suits"
                    className={`w-100 ${styles.imageItem}`}
                  />
                  <p>The Numbers</p>
                </Col>
                <Col xs={12} md>
                  <h2>The Numbers</h2>
                  <p className={`font-weight-light ${styles.leading}`}>
                    The numbers’ pips are in a different position. These changes
                    feature the face cards, something different, good for
                    performance, and playable.
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="bg-dark g-0">
        <Col>
          <Row className="container mx-auto py-5">
            <Col>
              <Row className="mb-4">
                <Col xs={12} md>
                  <h2>Features</h2>
                  <ul className={`font-weight-light ${styles.leading}`}>
                    <li>100% custom deck (52 playing cards & 2 jokers)</li>
                    <li>
                      Unique artwork on the back and the tuck box with UV
                      Coating
                    </li>
                    <li>Limited to 500</li>
                    <li>Only on Kickstarter</li>
                    <li>Vintage Stock</li>
                    <li>Legendary Finish</li>
                    <li>
                      Printed in Taiwan by TWPCC (Taiwan Playing Card Co.)
                    </li>
                    <li>Distributed by Waltty (Waltty Playing Card Studio)</li>
                  </ul>
                  <p>
                    This is a limited edition of only 500 decks and never will
                    be reprinted.
                  </p>
                </Col>
                <Col xs={12} md className="text-center">
                  <Image
                    src={Intro07}
                    alt="Aura playing cards"
                    className={`w-100 ${styles.imageItem}`}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={4} className="text-center mb-4">
                  <Image
                    src={Intro11}
                    alt="Aura playing cards"
                    className={`w-100 ${styles.imageMiniItem}`}
                  />
                </Col>
                <Col xs={12} md={4} className="text-center mb-4">
                  <Image
                    src={Intro12}
                    alt="Aura playing cards"
                    className={`w-100 ${styles.imageMiniItem}`}
                  />
                </Col>
                <Col xs={12} md={4} className="text-center mb-4">
                  <Image
                    src={Intro13}
                    alt="Aura playing cards"
                    className={`w-100 ${styles.imageMiniItem}`}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={4} className="text-center mb-4">
                  <Image
                    src={Intro14}
                    alt="Aura playing cards"
                    className={`w-100 ${styles.imageMiniItem}`}
                  />
                </Col>
                <Col xs={12} md={4} className="text-center mb-4">
                  <Image
                    src={Intro15}
                    alt="Aura playing cards"
                    className={`w-100 ${styles.imageMiniItem}`}
                  />
                </Col>
                <Col xs={12} md={4} className="text-center mb-4">
                  <Image
                    src={Intro16}
                    alt="Aura playing cards"
                    className={`w-100 ${styles.imageMiniItem}`}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  );
}

export default AuraPlayingCards;
